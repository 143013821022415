:root {
    --green: #439416;
    --white: rgb(255, 255, 255);
    --white-opaque: rgba(255, 255, 255, 0.75);
    --light-grey: linear-gradient(0deg, rgba(227, 223, 223, 1) 0%, #e8e8e8 50%, rgba(227, 223, 223, 1) 100%);
    --black-opaque: rgba(0, 0, 0, 0.25);
    --black: rgba(0, 0, 0, 1);
    --borg: rgb(250, 230, 19);
    --bakstain: #e32c39;
    --bakstain-light: #ffe8e9;
    --pronk: rgb(36, 168, 162, 1);
    --pronk-dark: rgb(39, 89, 87);
    --klei: rgb(70, 71, 63);
    --kiem: rgb(22, 156, 89);
    --groninger-paard: rgb(12, 12, 12);
    --groninger-paard-opaque: rgba(0, 0, 0, 0.40);

    --spacing-xl: 14px;
    --spacing-m: 7px;
    --padding-xl: 14px 14px 14px 14px;
    --padding-m: 7px 7px 7px 7px;


    --default-box-shadow: inset 0px 0px 0px 2px var(--groninger-paard);
    --button-box-shadow: inset 0px -4px 0px 0px var(--groninger-paard), var(--default-box-shadow);
    --floating-shadow: 0px 0.1px 0.1px var(--black-opaque),
    -0.1px 2.7px 2.9px -1.4px var(--black-opaque),
    -0.7px 12.4px 13.5px -2.7px var(--black-opaque);



    --animate-fade-in: fadeIn 500ms ease-out forwards;
    --mini-radius: 2px;
    --default-radius: 8px;
    --heavy-radius: 25px;
    --full-circle-radius: 100%;

    --font-size-default: 18px;
    --font-size-s: .8em;
    --font-size-xl: 1.2em;
    --font-size-xxl: 1.6em;
}

body,
html {
    padding: 0px;
    margin: 0px;
    overscroll-behavior: none;
    height: 100%;
    font-size: var(--font-size-default);
    color: var(--groninger-paard);
    background: var(--borg);
}

.App {
    min-width: 300px;
    max-width: 800px;
    width: 100%;
    height: 100%;
    margin: 0px auto;
}

a {
    text-decoration: none;
    color: inherit;
}

form {
    margin: 0px auto;
}



form.error-form input {
    box-shadow: inset 0px 0px 0px 2px var(--bakstain) !important;
    background: var(--bakstain-light);
    animation: error 500ms;
}

form.register-success {
    animation: slideAwayToBottom 500ms;
    animation-fill-mode: forwards;
}

.success-message {
    cursor: pointer;
    visibility: hidden;
    text-align: center;
    font-size: var(--font-size-xl);
    position: absolute;
    width: 250px;
    left: calc(50% - 125px);
    animation: slideInFromTop 500ms;
    animation-delay: 200ms;
    animation-fill-mode: forwards;
}

.success-message h1 {
    font-weight: 800;
}

@keyframes slideAwayToBottom {
    0% {
        opacity: 1;
        transform: translateY(0px);
        visibility: visible;
    }

    100% {
        opacity: 0;
        transform: translateY(50px);
        visibility: hidden;
    }
}

@keyframes slideInFromTop {
    0% {
        visibility: visible;
        opacity: 0;
        transform: translateY(-50px);
    }

    100% {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
    }
}

.error-message {
    margin-bottom: var(--spacing-xl);
    display: block;
    text-align: center;
    font-weight: 600;
    animation: error-message 500ms;
}

@keyframes error-message {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes error {
    0% {
        transform: translateX(0px);
    }

    25% {
        transform: translateX(4px);
    }

    75% {
        transform: translateX(-4px);
    }

    100% {
        transform: translateX(0px);
    }
}

.horizontal-rule {
    margin-left: var(--spacing-xl);
    margin-right: var(--spacing-xl);
    margin-top: 0px;
    margin-bottom: 0px;
    border: none;
    size: 2px;
    height: 2px;
    background: var(--groninger-paard);
    color: var(--groninger-paard);
}

input {
    border: none;
    border-radius: 0px;
    font-size: var(--font-size-xl);
    background: var(--white);
    display: block;
    padding: var(--padding-xl);
}

input.input-chain:first-of-type {
    border-top-left-radius: var(--default-radius);
    border-top-right-radius: var(--default-radius);
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

input.input-chain:last-of-type {
    border-bottom-left-radius: var(--default-radius);
    border-bottom-right-radius: var(--default-radius);
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    box-shadow: 0px 1px 0px 0px #0000000d;
}

input.input-chain.darker:last-of-type {
    box-shadow: none;
}

button {
    cursor: pointer;
    border: none;
    line-height: 30px;
    outline: none;
    color: var(--borg);
    background: black;
    border-radius: var(--default-radius);
    padding-left: var(--spacing-xl);
    padding-right: var(--spacing-xl);
    text-shadow: none;
    font-size: var(--font-size-default);
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
}

button.call-to-action {
    line-height: 45px;
    min-width: 100px;
    background: var(--white);
    color: var(--groninger-paard);
    box-shadow: var(--button-box-shadow);
}

button.call-to-action.login {
    margin-top: var(--spacing-xl);
    float: left;
}

button.like-a-link {
    line-height: 45px;
    font-weight: normal;
    text-transform: none;
    color: var(--black);
    text-decoration: underline;
    padding-left: 0px;
    padding-right: 0px;
    border-radius: 0px;
    background: none;
}

button.like-a-link.register {
    float: right;
    margin-top: var(--spacing-xl);
    margin-right: var(--spacing-xl);
}


button.btn-close {
    position: fixed;
    z-index: 999;
    border-radius: 100px;
    top: var(--spacing-xl);
    right: var(--spacing-xl);
    line-height: 40px;
}

.login-dialog {
    padding: var(--padding-xl);
    padding-bottom: calc(var(--spacing-xl) + var(--spacing-xl));
    bottom: env(safe-area-inset-bottom, 0px);
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0%);
}

.login-dialog form {
    box-shadow: 0px 0px 0px 2px var(--groninger-paard), 0px 2px 0px 2px var(--groninger-paard);
    border-radius: var(--default-radius);
}

.register-dialog {
    width: fit-content;
    margin: 0px auto;
}

.register-dialog form {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-xl);
    background: var(--white);
    box-shadow: 0px 0px 0px 2px var(--groninger-paard), 0px 2px 0px 2px var(--groninger-paard);
    border-radius: var(--default-radius);
    margin-top: var(--spacing-xl);
}

.intro-dialog {
    text-align: left;
    padding: var(--padding-xl);
    font-size: var(--font-size-xxl);
}

.intro-dialog h1 {
    text-transform: uppercase;
    font-size: 1.2em;
    font-weight: 400;
}

.intro-dialog p {
    margin: var(--padding-xl);
}

.intro-dialog svg#logo {
    width: 100px;
    display: block;
    animation: compassCircle 5s;
    animation-delay: 300ms;
}

.intro-dialog svg#logo .needle {
    transform-origin: 50% 50%;
    animation: compassNeedle 5s;
    animation-delay: 300ms;
}

@keyframes compassCircle {
    0% {
        transform: rotate(0deg);
    }

    30% {
        transform: rotate(5deg);
    }

    70% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes compassNeedle {
    0% {
        transform: rotate(0deg);
    }

    20% {
        transform: rotate(-10deg);
    }

    50% {
        transform: rotate(10deg);
    }

    80% {
        transform: rotate(-5deg);
    }


    100% {
        transform: rotate(0deg);
    }
}

@keyframes compassNeedleRandom {
    0% {
        transform: rotate(0deg);
    }

    20% {
        transform: rotate(70deg);
    }

    40% {
        transform: rotate(20deg);
    }

    50% {
        transform: rotate(-35deg);
    }

    70% {
        transform: rotate(66deg);

    }

    80% {
        transform: rotate(110deg);
    }


    100% {
        transform: rotate(0deg);
    }
}

.container {
    text-align: center;
    max-width: 500px;
    margin: 0px auto;
    padding-bottom: 75px;
}

.bottom-container {
    background: var(--borg);
    font-size: var(--font-size-default);
    z-index: 999;
    position: fixed;
    bottom: 0px;
    padding-bottom: env(safe-area-inset-bottom, 0);
    width: 100%;
    max-width: 500px;
    text-align: center;
    margin: 0px auto;
    display: flex;
}

.bottom-container button {
    position: relative;
    line-height: 45px;
    margin: var(--spacing-xl);
}

.bottom-container button.scan {
    position: absolute;
    width: 80px;
    padding: 0px;
    right: 0px;
    bottom: 0px;
    background: var(--white);
    box-shadow: var(--button-box-shadow);
    color: var(--groninger-paard);
    aspect-ratio: 1;
}

.bottom-container button.community {
    background: var(--borg);
    box-shadow: var(--button-box-shadow);
    color: var(--groninger-paard);
}

.community-wrap-main {
    font-size: var(--font-size-s);
    padding: var(--spacing-xl);
    border-radius: var(--default-radius);
    background: var(--groninger-paard);
    color: var(--borg);
    margin: var(--spacing-xl);
    float: left;
}

.modal {
    z-index: 999;
    -webkit-user-select: none;
    user-select: none;
    background: var(--white);
    color: var(--groninger-paard);
    box-shadow: var(--floating-shadow);
    position: fixed;
    bottom: var(--spacing-xl);
    bottom: env(safe-area-inset-bottom, var(--spacing-xl));
    max-height: calc(100vh - 85px);
    left: var(--spacing-xl);
    right: var(--spacing-xl);
    max-width: 400px;
    margin: 0px auto;
    margin-bottom: var(--spacing-xl);
    padding: var(--padding-xl);
    border-radius: var(--default-radius);
    animation: modalPopUp 500ms;
}

.modal.bottom-sheet {
    border-radius: 0px;
    max-height: fit-content;
    height: fit-content;
    position: absolute;
    min-height: 50vh;
    max-width: 500px;
    bottom: 0px;
    top: 50vh;
    left: 0px;
    right: 0px;
    padding-bottom: var(--spacing-xl);
    padding-bottom: env(safe-area-inset-bottom, var(--spacing-xl));
    margin: 0px auto;
    animation: bottomSheetPopUp 500ms;
}

.modal.bottom-scan-sheet {
    border-radius: 0px;
    overflow: hidden;
    backdrop-filter: blur(3px);
    box-shadow: var(--default-box-shadow);
    height: fit-content;
    background: var(--black-opaque);
    position: fixed;
    max-width: 500px;
    bottom: 0px;
    top: inherit;
    left: 0px;
    right: 0px;
    padding: var(--spacing-xl) 0px 0px 0px;
    padding-bottom: var(--spacing-xl);
    padding-bottom: env(safe-area-inset-bottom, var(--spacing-xl));
    margin: 0px auto;
    animation: bottomSheetPopUp 500ms;
}

button.btn-close-modal {
    z-index: 999;
    padding: 0px;
    width: 40px;
    background: var(--groninger-paard);
    border-radius: var(--default-radius);
    color: var(--white);
    font-size: 1.1em;
}

.modal button.btn-close-modal {
    position: absolute;
    top: var(--spacing-xl);
    right: var(--spacing-xl);
}

.modal.bottom-sheet button.btn-close-modal {
    position: sticky;
    margin-bottom: var(--spacing-xl);
    left: 100%;
    font-size: 1.1em;
}

button.objects-found-btn {
    border-radius: var(--mini-radius);
    margin: var(--padding-xl);
    line-height: 40px;
    width: 200px;
}

.explainer {
    opacity: 0;
    max-width: 250px;
    text-align: center;
    margin: var(--spacing-xl) auto;
    padding: var(--spacing-xl);
    animation: var(--animate-fade-in);
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

.explainer.on-top {
    background: var(--white-opaque);
    box-shadow: 0px 0px 25px 25px #fff;
    backdrop-filter: blur(2px);
    border-radius: 100%;
    text-align-last: center;
    position: absolute;
    width: calc(100% - 100px);
    left: 50px;
    max-width: none;
    padding: 50px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0px;
    z-index: 100;
}

.profile-card {
    background: var(--borg);
    z-index: 800;
    position: sticky;
    top: 0px;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    height: 172px;
    opacity: 0;
    animation: var(--animate-fade-in);
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

.profile {
    cursor: pointer;
    padding: var(--padding-xl);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
}

.profile .profile-username {
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
    line-height: 50px;
    height: 50px;
    margin-right: var(--spacing-xl);
    border-radius: var(--default-radius);
    box-shadow: var(--button-box-shadow);
    width: 100%;
}

.profile .profile-avatar {
    background: var(--white);
    border-radius: var(--default-radius);
    box-shadow: var(--button-box-shadow);
    height: 50px;
}

.profile .points-badge {
    text-align: right;
    width: 145px;
}

.profile .points-badge::before {
    position: absolute;
    height: 100%;
    right: var(--spacing-xl);
    left: var(--spacing-xl);
    content: '00000';
    text-align: right;
    opacity: .1;
}

.stats-wrap ul {
    width: 100%;
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    display: table;
}

.stats-wrap li {
    align-self: auto;
    cursor: pointer;
    text-align: center;
    display: table-cell;
    padding-bottom: var(--spacing-xl);
    padding-left: var(--spacing-xl);
}

.stats-wrap li:last-of-type {
    padding-right: var(--spacing-xl);

}

.stats-wrap li .meter {
    overflow: hidden;
    position: relative;
    border-radius: var(--default-radius);
    box-shadow: var(--default-box-shadow);
    color: var(--groninger-paard);
    vertical-align: middle;
    margin: 0px auto;
}

.meter span.label {
    display: block;
    position: relative;
    text-align: center;
    padding: var(--spacing-m);
    color: var(--borg);
    font-size: var(--font-size-s);
    background: var(--groninger-paard);
}

.meter span.number {
    display: block;
    font-size: var(--font-size-xxl);
    line-height: 50px;
}

.community-sheet {
    opacity: 0;
    animation: var(--animate-fade-in);
    animation-fill-mode: forwards;
    animation-delay: 300ms;
    width: 100%;
}

.community-sheet h1 {
    margin: 0px;
    padding: var(--padding-xl);
    position: sticky;
    text-align: left;
    top: 0px;
}

.community-sheet ul {
    margin: 0px;
    padding: var(--padding-xl);
    list-style-type: none;
    text-align: left;
}

.community-sheet li {
    padding: var(--padding-m);
    margin-bottom: var(--spacing-xl);
    box-shadow: 0px 0px 0px 2px var(--groninger-paard-opaque);
    border-radius: var(--default-radius);
    display: grid;
    grid-template-columns: 2fr auto auto;
}

.community-sheet li span.username {
    text-transform: uppercase;
    font-size: var(--font-size-s);
    font-weight: 800;
    padding: var(--padding-m);
}

.community-sheet li span.object-count {
    box-shadow: inset 0px 0px 0px 2px var(--groninger-paard-opaque);
    background: var(--borg);
    color: var(--groninger-paard);
    font-size: var(--font-size-s);
    margin-right: var(--spacing-m);
    padding: var(--padding-m);
    border-radius: var(--default-radius);
}

.collectables-grid-wrap {
    position: relative;
}

.collectables-grid-wrap.geo-object {
    opacity: 0;
    animation: var(--animate-fade-in);
    animation-fill-mode: forwards;
    animation-delay: 300ms;
    min-height: 50vh;
}

.collectables-grid-wrap h1 {
    text-transform: uppercase;
    font-weight: 400;
    float: left;
    margin: 0px;
    padding-top: var(--spacing-m);
    padding-bottom: var(--spacing-m);
    position: sticky;
    left: 0px;
    top: 40px;
    font-size: var(--font-size-default);
    color: var(--groninger-paard);
    line-height: 25px;
    writing-mode: vertical-rl;
    text-orientation: upright;
}

.collectables-grid-header {
    text-transform: uppercase;
    display: block;
    text-align: center;
    font-size: var(--font-size-s);
    margin-top: var(--spacing-xl);
    margin-left: var(--spacing-xl);
    margin-right: var(--spacing-xl);
}

.collectables-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(78px, 1fr));
    justify-items: center;
    align-items: center;
    gap: var(--spacing-m);
    margin: 0px auto;
    position: relative;
    padding: var(--spacing-xl);
    opacity: 0;
    animation: var(--animate-fade-in);
    animation-fill-mode: forwards;
    animation-delay: 300ms;
}

.collectables-grid.geo-object {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: var(--spacing-m);
}

.collectables-grid.year {
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    gap: var(--spacing-m);
}

.collectables-grid.soort {
    overflow-x: scroll;
    grid-auto-flow: column;
    grid-template-rows: auto auto;
    /* Two rows */
    grid-template-columns: repeat(auto-fill, minmax(min(100px, 100%), 1fr));
}

.collected-bottom-sheet {
    display: grid;
    justify-items: center;
    align-items: center;
    margin: 0px auto;
    position: relative;
    opacity: 0;
    animation: var(--animate-fade-in);
    animation-fill-mode: forwards;
    animation-delay: 300ms;
}

.collected-bottom-sheet.soort {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: var(--spacing-m);
}

.collected-bottom-sheet.year {
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
    gap: var(--spacing-m);
}

.collected-bottom-sheet.geo-object {
    display: block;
}

.collected-bottom-sheet.geo-object .geo-object-list-item {
    align-items: center;
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-column-gap: var(--spacing-xl);
    grid-row-gap: 0px;
    margin-bottom: var(--spacing-xl);
}

.collected-bottom-sheet.area {
    display: block;
    text-align: center;
}

.collected-bottom-sheet.area .area-badge {
    margin-bottom: var(--spacing-xl);
    margin-right: var(--spacing-xl);
    display: inline-block;
}

.collected-bottom-sheet.avatar {
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    padding-top: 35px;
    gap: 0;
}

/* Scan page */
.scan-sheet {
    position: relative;
    width: 100%;
}

.scan-sheet .fallback-info {
    position: absolute;
    backdrop-filter: blur(40px);
    padding-top: 45px;
    top: calc(0px - var(--spacing-xl));
    height: 100%;
    z-index: 900;
    opacity: 0;
    animation: var(--animate-fade-in);
    animation-fill-mode: forwards;
}

.scan-sheet .fallback-info div {
    border-radius: var(--default-radius);
    text-align: center;
    margin: var(--padding-xl);
    background: var(--white);
    opacity: 0;
    animation: var(--animate-fade-in);
    animation-delay: 500ms;
    animation-fill-mode: forwards;
}

.fallback-info .default-explainer {
    padding: var(--padding-xl);
    box-shadow: var(--default-box-shadow);
    display: block;
}

.fallback-info .generic-explainer {
    padding: var(--padding-xl);
    box-shadow: var(--default-box-shadow);
    display: none;
}

@media (pointer: coarse),
(hover: none) {
    .fallback-info .generic-explainer {
        display: block;
    }
}

.scan-sheet .fallback-info .ios-explainer {
    display: none;
    margin-top: 0px;
    padding: var(--padding-xl);
    box-shadow: var(--default-box-shadow);
    opacity: 0;
    animation: var(--animate-fade-in);
    animation-delay: 1000ms;
    animation-fill-mode: forwards;
}

.ios-explainer .ios-logo-grid {
    display: grid inline;
    grid-template-columns: auto auto;
}

.ios-explainer svg {
    width: 75px;
    margin: 0px auto;
    display: block;
    padding: var(--padding-xl);
}

/* CSS specific to iOS devices */
@supports (-webkit-touch-callout: none) {

    @media (pointer: coarse),
    (hover: none) {
        .scan-sheet .fallback-info .generic-explainer {
            display: none;
        }
    }

    .scan-sheet .fallback-info .ios-explainer {
        display: block;
    }
}

.scan-sheet .watch-location {
    z-index: 900;
    position: absolute;
    width: var(--spacing-m);
    height: var(--spacing-m);
    border-radius: var(--full-circle-radius);
    transition: background-color 1s ease;
    -moz-transition: background-color 1s ease;
    opacity: .5;
    padding: var(--padding-m);
    font-size: var(--font-size-s);
    margin-left: calc(var(--spacing-xl) + var(--spacing-m));
    margin-top: calc(var(--spacing-xl) + var(--spacing-xl) + var(--spacing-xl) + var(--spacing-m));
}

.scan-sheet .watch-location.active {
    background-color: var(--green);
}

.scan-sheet .watch-location.nonactive {
    background-color: var(--bakstain);
}

.scan-sheet .map-info {
    text-align: center;
    padding: var(--spacing-xl);
    bottom: 0px;
    position: sticky;
    border-radius: var(--default-radius);
    animation: bottomSheetPopUp 500ms;
}

.scan-sheet .map-info button.nearby-object-btn {
    box-shadow: var(--button-box-shadow);
    padding: var(--spacing-xl);
    background: var(--white);
    margin-bottom: var(--spacing-xl);
    font-weight: inherit;
    color: var(--groninger-paard);
}

.nearby-object-btn .distance-label {
    font-size: var(--font-size-s);
    color: var(--groninger-paard-opaque);
}

.scan-sheet .map-info span.too-far-object {
    box-shadow: var(--button-box-shadow);
    position: relative;
    padding: var(--spacing-xl);
    margin-bottom: var(--spacing-m);
    background: var(--white);
    border-radius: var(--default-radius);
    color: var(--groninger-paard);
    display: inline-block;
    animation: mysteryFloat 5s infinite;
}

@keyframes mysteryFloat {
    0% {
        transform: translateY(0) scale(.8);
    }

    50% {
        transform: translateY(5px) scale(.8);
    }

    100% {
        transform: translateY(0) scale(.8);
    }
}

.scan-sheet .map-info span.too-far-object:last-child {
    animation: mysteryFloat1 7s infinite;
    opacity: .75;
}

@keyframes mysteryFloat1 {
    0% {
        transform: translateY(0) scale(.8);
    }

    50% {
        transform: translateY(4px) scale(.8);
    }

    100% {
        transform: translateY(0) scale(.8);
    }
}

.not-mystery-object {
    display: block;
    width: 30px;
    height: 30px;
    margin-right: var(--spacing-xl);
    background: var(--green);
    font-weight: 800;
    float: left;
    animation: var(--animate-fade-in);
    clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);
    ;
}

.mystery-object::after {
    content: "?";
    line-height: 30px;
}

.mystery-object {
    display: block;
    width: 30px;
    height: 30px;
    margin-right: var(--spacing-xl);
    color: var(--klei);
    border-radius: 100%;
    font-weight: 800;
    background: #e1e1e1;
    float: left;
}

.mystery-object::after {
    content: "?";
    line-height: 30px;
}

.scan-sheet #map {
    margin: var(--spacing-xl);
    margin-top: calc(var(--spacing-xl) + var(--spacing-xl) + var(--spacing-xl));
    border-radius: var(--default-radius);
    transform-style: preserve-3d;
    width: calc(100% - var(--spacing-xl) - var(--spacing-xl));
    box-shadow: var(--default-box-shadow), 0px 0px 200px -5px var(--black-opaque);
    aspect-ratio: 1;
}

@supports not (aspect-ratio: 1) {
    .scan-sheet #map {
        height: 360px;
    }
}

.scan-sheet #map .map-pointer-middle {
    position: absolute;
    left: calc(50% - 9px);
    width: 16px;
    background: var(--white);
    box-shadow: 0px 0px 0px 5px rgba(55, 55, 55, 0.50),
        0px 0px 0px 2px rgba(55, 55, 55, 0.31);
    height: 16px;
    top: calc(50% - 9px);
    z-index: 999;
    border-radius: 100px;
}

.leaflet-pane.leaflet-shadow-pane {
    display: none;
    /* removes outline from marker */
}

.points-badge {
    margin: 0px auto;
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: var(--default-radius);
    font-size: var(--font-size-s);
    font-weight: 400;
    text-shadow: 0px 0px 2px var(--groninger-paard-opaque);
    background-image: radial-gradient(circle farthest-corner at 10% 20%, rgba(14, 174, 87, 1) 0%, rgba(12, 116, 117, 1) 90%);
    color: var(--white);
    box-shadow: inset 0px 0px 0px 2px var(--groninger-paard-opaque);
    text-align: center;
}

.points-badge.large-size::after {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0px;
    opacity: 0;
    content: '';
    animation: moveLeftToRight 500ms ease-out;
    animation-delay: 1s;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(128, 186, 232, 0) 100%, rgba(125, 185, 232, 0) 100%);
}

.points-badge.tiny-size {
    padding: var(--padding-m);
    border-radius: var(--default-radius);
    min-width: 30px;
    display: inline-block;
}

.points-badge.small-size {
    line-height: 50px;
    height: 50px;
    padding-left: var(--spacing-xl);
    padding-right: var(--spacing-xl);
    font-size: var(--font-size-xxl);
}

.points-badge.medium-size {
    line-height: 75px;
    height: 75px;
    font-size: var(--font-size-xxl);
    max-width: 75px;
    border-radius: 100%;
}

.points-badge.medium-size:after {
    font-size: 10px;
    color: var(--white-opaque);
    text-shadow: none;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: 12px;
    content: "punten";
    line-height: initial;
}

.points-badge.large-size {
    width: 250px;
    height: 80px;
    margin: 0px auto;
    line-height: 80px;
    font-size: 3em;
    border-radius: var(--default-radius);
    box-shadow: inset 0px 0px 0px 2px var(--groninger-paard-opaque), 0px 0px 5px 0px #FFF, 0px 0px 50px -2px rgba(14, 174, 87, 1);
}

div.geo-object-badge {
    cursor: pointer;
    text-align: center;
    margin: 0px auto;
    position: relative;
}

div.geo-object-badge.medium-size {
    overflow: hidden;
    width: 80px;
    height: 80px;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
}

div.geo-object-badge.large-size {
    width: 100%;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
}

div.geo-object-badge.large-size img::after {
    filter: blur(50px);
}

div.geo-object-badge .geo-object-img-container {
    overflow: hidden;
    width: inherit;
    aspect-ratio: 1;
    margin: 0px auto;

}

div.geo-object-badge.medium-size .geo-object-img-container .geo-object-border {
    height: 80px;
    width: 80px;
    z-index: 100;
    position: absolute;
    border-radius: 100%;
    box-shadow: var(--default-box-shadow);
}

div.geo-object-badge.large-size .geo-object-img-container {
    aspect-ratio: initial;
    overflow: inherit;
}

div.geo-object-badge.large-size .geo-object-img-container img {
    animation: rotateIntoView 500ms;
    opacity: 0;
    animation-delay: 500ms;
    animation-fill-mode: forwards;
}

@keyframes rotateIntoView {
    0% {
        transform: rotateY(90deg);
        opacity: 0;
    }

    100% {
        transform: rotateY(0deg);
        opacity: 1;
    }

}

div.geo-object-badge.medium-size .geo-object-img-container img {
    position: relative;
    z-index: 100;
    padding: 0px;
    width: 80px;
    height: 80px;

}

div.geo-object-badge.large-size .geo-object-img-container img {
    z-index: 100;
    max-height: 200px;
    margin-top: -40px;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    box-shadow: 0px 50px 50px -50px var(--white);
}

div.geo-object-img-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

div.geo-object-badge-info .object-description {
    font-size: var(--font-size-s);
    display: inline-block;
    font-weight: 300;
    text-align: center;
}

div.geo-object-badge-info .object-url {
    text-decoration: underline;
    font-weight: 800;
    opacity: .7;
}

div.geo-object-badge-info .object-url::before {
    content: " ";
}

div.geo-object-badge-info .object-url::after {
    content: " → ";
}

div.geo-object-badge-info h2 {
    font-size: var(--font-size-xxl);
    text-align: center;
    margin: 0px;
}

div.geo-object-badge-info .object-badges {
    padding-left: var(--spacing-xl);
    padding-right: var(--spacing-xl);
    text-align: center;
}

div.geo-object-badge-info .object-badges .badge-row {
    padding-bottom: var(--spacing-xl);
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--spacing-m);
}

div.geo-object-badge-info .object-badges .area-wrap {
    padding-top: 40px;
    height: 110px;
}

div.geo-object-badge-info .object-badges .area-wrap #map {
    position: absolute;
    bottom: 0px;
    width: calc(100% - 15px);
    opacity: .8;
    height: 100px;
    padding-bottom: 60px;
    border-radius: var(--default-radius);
    margin-bottom: var(--spacing-m);
    transform: translateX(-20px);
    box-shadow: var(--floating-shadow);
}

div.geo-object-badge-info .object-badges .area-wrap .area-badge {
    margin: 0px auto;
    margin-top: 50px;

}

/* Collectables */
div.year-badge {
    cursor: pointer;
    text-align: center;
    user-select: none;
    display: inline-block;
    border-radius: var(--full-circle-radius);
    font-weight: 800;
    aspect-ratio: 1;
    background-image: linear-gradient(to right, #ffe57d 0%, #ffd60b 100%);
    color: #8f7200;
    box-shadow: inset 0px 0px 0px 5px #b79200;
}

.year-badge.century-17 {
    background-image: linear-gradient(to right, #ffe57d 0%, #ffd60b 100%);
    color: #8f7200;
    box-shadow: inset 0px 0px 0px 5px #b79200;
}

.year-badge.locked {
    cursor: default;
    background: none;
    filter: grayscale(0.95);
    opacity: .2;
}

.year-badge.small-size {
    width: 60px;
    line-height: 60px;
}

.year-badge.medium-size {
    width: 75px;
    line-height: 75px;
    font-size: var(--font-size-xl);
}

.year-badge.large-size {
    width: 150px;
    line-height: 150px;
    font-size: 2.5em;
    position: relative;
}

.year-badge.large-size::after {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0px;
    opacity: 0;
    content: '';
    animation: moveLeftToRight 500ms ease-out;
    animation-delay: 0s;
    animation-delay: 1s;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(128, 186, 232, 0) 100%, rgba(125, 185, 232, 0) 100%);
}

div.soort-badge {
    margin: 0px auto;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    color: var(--groninger-paard);
    border-radius: 100%;
    background-image: linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%);
    aspect-ratio: 1;
}

div.soort-badge.medium-size {
    width: 75px;
    height: 75px;
    font-size: var(--font-size-s);
}

div.soort-badge.large-size {
    position: relative;
    width: 120px;
    height: 120px;
}

div.soort-badge.large-size::after {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0px;
    opacity: 0;
    content: '';
    animation: moveLeftToRight 500ms ease-out;
    animation-delay: 0s;
    animation-delay: 1s;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(128, 186, 232, 0) 100%, rgba(125, 185, 232, 0) 100%);
}

div.soort-badge.locked {
    transform: scale(.85);
    cursor: default;
    filter: grayscale(0.95);
    opacity: .2;
}

.soort-badge div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.soort-badge .glas {
    text-transform: uppercase;
    font-size: 1.8em;
    font-weight: 300;
    --s: 40px;
    --c1: #3c89e8;
    --c2: #7cb3eb;
    --g: , var(--c1) 25%, var(--c2) 0 50%, #0000 0;
    background:
        repeating-conic-gradient(var(--c1) 0 30deg, #0000 0 150deg, var(--c2) 0 50%) calc(1.5*var(--s)) calc(.865*var(--s)),
        conic-gradient(from 30deg at 75% 75%var(--g)),
        conic-gradient(from -30deg at 75% 25%var(--g)),
        conic-gradient(from 150deg at 25% 75%var(--g)),
        conic-gradient(from 210deg at 25% 25%var(--g)),
        repeating-conic-gradient(var(--c1) 0 30deg, var(--c2) 0 60deg);
    background-size: calc(3*var(--s)) calc(1.73*var(--s))
}

.soort-badge .glas span {
    background: #7cb4ebc7;
    color: #030c17;
    width: 100%;
}

.soort-badge .architectuur {
    font-size: .8em;
    font-weight: 800;
    --s: 75px;
    --_g: #0000 90deg, #366 0;
    background:
        conic-gradient(from 90deg at 2px 2px, var(--_g)),
        conic-gradient(from 90deg at 1px 1px, var(--_g));
    background-size: var(--s) var(--s), calc(var(--s)/5) calc(var(--s)/5);
}

.soort-badge .architectuur span {
    background: #366;
    color: #FFF;
}

.soort-badge .wandsculptuur {
    --s: 18px;
    /* control the size*/
    --c1: #f86466;
    --c2: #000000;
    --c3: #ffffff;

    --_g: conic-gradient(at 50% 25%, #0000 75%, var(--c1) 0);
    background:
        var(--_g), var(--_g) var(--s) var(--s),
        var(--_g) calc(2*var(--s)) calc(2*var(--s)),
        var(--_g) calc(3*var(--s)) calc(3*var(--s)),
        repeating-linear-gradient(135deg, var(--c2) 0 12.5%, var(--c3) 0 25%);
    background-size: calc(4*var(--s)) calc(4*var(--s))
}

.soort-badge .wandsculptuur span {
    background: #f86466;
    border-radius: 100%;
    padding: var(--padding-m);
}

.soort-badge .plaquette {}

.soort-badge .fontein {
    font-weight: 800;
    color: #000;
    --s: 100px;
    --c1: #5cc8ef35;
    --c2: #1535802d;

    --_g:
        var(--c1) 0% 5%, var(--c2) 6% 15%, var(--c1) 16% 25%, var(--c2) 26% 35%, var(--c1) 36% 45%,
        var(--c2) 46% 55%, var(--c1) 56% 65%, var(--c2) 66% 75%, var(--c1) 76% 85%, var(--c2) 86% 95%,
        #0000 96%;
    background:
        radial-gradient(50% 50% at 100% 0, var(--_g)),
        radial-gradient(50% 50% at 0 100%, var(--_g)),
        radial-gradient(50% 50%, var(--_g)),
        radial-gradient(50% 50%, var(--_g)) calc(var(--s)/2) calc(var(--s)/2) var(--c1);
    background-size: var(--s) var(--s);
}

.soort-badge .fontein span {
    background: #e0eeffb3;
    width: 100%;
    color: #1b3d49d6;
}

.soort-badge .nieuwe-media {
    background: linear-gradient(to right, #00CED1, #20B2AA);
    color: #FFFFFF;
}

.soort-badge .monument {
    font-weight: 800;
    font-size: var(--font-size-s);
    --s: 40px;
    /* control the size */

    --c: #333 20% 30%;
    background:
        linear-gradient(135deg, #0000 20%, var(--c), #0000 0),
        repeating-linear-gradient(45deg, var(--c), #D3643B 0 70%);
    background-size: var(--s) var(--s);
}

.soort-badge .monument span {
    background: #D3643B;
    color: #000;
    box-shadow: 0px -5px 0px #333, 0px 5px 0px #333;
    width: 100%;
}

.soort-badge .vormgeving {
    --s: 106px;
    --c1: #EAFDE6;
    --c2: #519548;

    background:
        conic-gradient(from 30deg at 87.5% 75%, var(--c1) 60deg, var(--c2) 0 120deg, #0000 0) 0 calc(.2165*var(--s)),
        conic-gradient(from -90deg at 50% 25%, var(--c2) 60deg, var(--c1) 0 180deg, #0000 0),
        conic-gradient(from 90deg at 50% 75%, var(--c2) 120deg, var(--c1) 0 180deg, #0000 0),
        conic-gradient(from -30deg at 87.5% 50%, var(--c2) 120deg, var(--c1) 0 240deg, #0000 0),
        conic-gradient(from 90deg at 37.5% 50%, var(--c2) 120deg, var(--c1) 0 180deg, var(--c2) 0 240deg, var(--c1) 0);
    background-size: var(--s) calc(.866*var(--s));
}

.soort-badge .vormgeving span {
    background: #519548;
    color: #EAFDE6;
    width: 100%;
}

.soort-badge .kunst-wegdek {
    color: #ffffff;
    font-weight: 800;
    --s: 50px;
    --c: #463b34;
    --_g: #0000 8%, var(--c) 0 17%, #0000 0 58%;
    background:
        linear-gradient(135deg, #0000 20.5%, var(--c) 0 29.5%, #0000 0) 0 calc(var(--s)/4),
        linear-gradient(45deg, var(--_g)) calc(var(--s)/2) 0,
        linear-gradient(135deg, var(--_g), var(--c) 0 67%, #0000 0),
        linear-gradient(45deg, var(--_g), var(--c) 0 67%, #0000 0 83%, var(--c) 0 92%, #0000 0),
        #927936;
    background-size: var(--s) var(--s);
}

.soort-badge .kunst-wegdek span {
    background: #463b34;
    width: 100%;
    padding: var(--padding-m);
}

.soort-badge .landart {
    --s: 45px;
    --c: #1a144a;
    --_g: conic-gradient(var(--c) 25%, #0000 0) 0 0;
    background:
        var(--_g)/calc(2*var(--s)) calc(var(--s)/9.5),
        var(--_g)/calc(var(--s)/9.5) calc(2*var(--s)),
        repeating-conic-gradient(#0000 0 25%, var(--c) 0 50%) var(--s) 0 /calc(2*var(--s)) calc(2*var(--s)),
        radial-gradient(50% 50%, #2eb044 98%, var(--c)) 0 0/var(--s) var(--s);
}

.soort-badge .landart span {
    background: #1a144a;
    color: #2eb044;
    font-weight: 800;
    font-size: 1.3em;
}

.soort-badge .mozaiek {
    --s: 170px;
    /* control the size */

    --_g: #dc9d37 25%, #fed450 0 50%, #0000 0;
    --_l1: #fff 0 1px, #0000 0 calc(25% - 1px), #fff 0 25%;
    --_l2: #fff 0 1px, #0000 0 calc(50% - 1px), #fff 0 50%;
    background:
        repeating-linear-gradient(45deg, var(--_l1)),
        repeating-linear-gradient(-45deg, var(--_l1)),
        repeating-linear-gradient(0deg, var(--_l2)),
        repeating-linear-gradient(90deg, var(--_l2)),
        conic-gradient(from 135deg at 25% 75%, var(--_g)),
        conic-gradient(from 225deg at 25% 25%, var(--_g)),
        conic-gradient(from 45deg at 75% 75%, var(--_g)),
        conic-gradient(from -45deg at 75% 25%, var(--_g)),
        repeating-conic-gradient(#125c65 0 45deg, #bc4a33 0 90deg);
    background-size: var(--s) var(--s);
}

.soort-badge .mozaiek span {
    background: #dc9d37;
    color: #FFF;
    font-weight: 800;
    box-shadow: 0px 2px 0px 0px #FFF, 0px -2px 0px 0px #FFF;
    width: 100%;
}

.soort-badge .relief {
    font-size: 1.4em;
    font-weight: 800;
    --s: 35px;
    --c: #fff;
    --a: from -30deg at;
    background:
        linear-gradient(#0000 50%, #0004 0),
        conic-gradient(var(--a) 90%, var(--c) 240deg, #0000 0),
        conic-gradient(var(--a) 75%, #46aa6e 240deg, #0000 0),
        conic-gradient(var(--a) 25%, #0000 240deg, var(--c) 0),
        conic-gradient(var(--a) 40%, var(--c) 240deg, #46aa6e 0);
    background-size: calc(1.5*var(--s)) var(--s);
}

.soort-badge .relief span {
    background: #46aa6e;
    width: 100%;
    color: #FFF;
}

.soort-badge .vrijstaande-sculptuur {
    --s: 82px;
    /* control the size */
    --c1: #b2b2b2;
    --c2: #ffffff;
    --c3: #d9d9d9;
    --_g: var(--c3) 0 120deg, #0000 0;
    background:
        conic-gradient(from -60deg at 50% calc(100%/3), var(--_g)),
        conic-gradient(from 120deg at 50% calc(200%/3), var(--_g)),
        conic-gradient(from 60deg at calc(200%/3), var(--c3) 60deg, var(--c2) 0 120deg, #0000 0),
        conic-gradient(from 180deg at calc(100%/3), var(--c1) 60deg, var(--_g)),
        linear-gradient(90deg, var(--c1) calc(100%/6), var(--c2) 0 50%,
            var(--c1) 0 calc(500%/6), var(--c2) 0);
    background-size: calc(1.732*var(--s)) var(--s);
}

.soort-badge .vrijstaande-sculptuur span {
    background: #ffffffd3;
}

.soort-badge .gevelsteen {
    text-transform: lowercase;
    font-size: var(--font-size-xl);
    font-weight: 800;
    --s: 24px;
    /* control the size */
    --c1: #EB6841;
    --c2: #EDC951;

    background:
        conic-gradient(from -45deg, var(--c1) 90deg, #0000 0 180deg, var(--c2) 0 270deg, #0000 0) 0 calc(var(--s)/2)/var(--s) var(--s),
        conic-gradient(from 135deg at 50% 0, var(--c1) 90deg, var(--c2) 0) 0 0/calc(2*var(--s)) var(--s);
}

.soort-badge .aan-bouwwerk {
    --s: 50px;
    /* control the size*/
    --c1: #fcd036;
    --c2: #987f69;

    --_g: var(--c1) 0 100%, #0000 102%;
    background:
        conic-gradient(#0000 75%, var(--_g)) calc(var(--s)/4) calc(var(--s)/4),
        radial-gradient(65% 65% at 50% -50%, var(--_g)),
        radial-gradient(65% 65% at -50% 50%, var(--_g)),
        radial-gradient(65% 65% at 50% 150%, var(--_g)),
        radial-gradient(65% 65% at 150% 50%, var(--_g)) var(--c2);
    background-size: var(--s) var(--s);
}

.soort-badge .aan-bouwwerk span {
    background: #fcd036;
    color: #987f69;
    padding: 5px;
    font-weight: 800;
}

.soort-badge .graffiti {
    --s: 60px;
    /* control the size*/
    --c1: #e08e79;
    --c2: #f1d4af;
    --c3: #955e3e;

    background:
        conic-gradient(from 15deg at 86.6%,
            var(--c3) 25%, var(--c2) 0 150deg, #0000 0),
        conic-gradient(from -75deg at 50% 13.39%,
            var(--c1) 60deg, var(--c3) 0 150deg,
            var(--c2) 0 210deg, #0000 0),
        conic-gradient(from 15deg at 36.6% 63.4%,
            var(--c1) 60deg, var(--c3) 0 150deg,
            var(--c1) 0 210deg, var(--c2) 0 75%, var(--c3) 0);
    background-size: var(--s) var(--s);
}

.soort-badge .graffiti span {
    background: #955e3e;
    color: #f1d4af;
    padding: var(--padding-m);
}

.soort-badge .tableau {
    background: brown;
    color: #FFF;
}

.soort-badge .tableau span {
    background: #d77171;
    padding: var(--padding-m);
    border-radius: var(--heavy-radius);
}

.soort-badge .lichtwerk {
    background: #f1f141;
    color: rgb(106, 106, 25);
}

.soort-badge .speelobject {
    font-size: var(--font-size-s);
}

.soort-badge .schilderkunst {
    font-size: var(--font-size-s);
}

.soort-badge .vloer-grond {
    --s: 164px;
    --c1: #774f38;
    --c2: #f1d4af;

    --_g: radial-gradient(#0000 60%, var(--c1) 61% 63%, #0000 64% 77%, var(--c1) 78% 80%, #0000 81%);
    --_c: , #0000 75%, var(--c2) 0;
    background:
        conic-gradient(at 12% 20% var(--_c)) calc(var(--s)* .44) calc(.9*var(--s)),
        conic-gradient(at 12% 20% var(--_c)) calc(var(--s)*-.06) calc(.4*var(--s)),
        conic-gradient(at 20% 12% var(--_c)) calc(.9*var(--s)) calc(var(--s)* .44),
        conic-gradient(at 20% 12% var(--_c)) calc(.4*var(--s)) calc(var(--s)*-.06),
        var(--_g), var(--_g) calc(var(--s)/2) calc(var(--s)/2) var(--c2);
    background-size: var(--s) var(--s);
}

.soort-badge .vloer-grond span {
    background: #f1d4af;
    box-shadow: inset 0px 0px 0px 2px #774f38;
    border-radius: var(--heavy-radius);
    color: #774f38;
    font-weight: 800;
    font-size: var(--font-size-xl);
    padding: 8px;
}

div.area-badge {
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    color: var(--white);
    background: #00B4DB;
    background: linear-gradient(to top, #0083B0, #00B4DB);
    border-radius: var(--heavy-radius);
    box-shadow: inset 0px 0px 0px 2px var(--pronk-dark);
    font-weight: 800;
    overflow: hidden;
    text-transform: uppercase;
    text-align: center;
    font-size: var(--font-size-s);
}

.area-badge.small-size {
    width: 40px;
    height: 40px;
    font-size: 5px;
    padding: 2px;
}

.area-badge.medium-size {
    width: fit-content;
    padding: var(--padding-xl);
}

.area-badge.large-size {
    margin: 0px auto;
    display: inline-block;
    padding: var(--padding-xl);
}

.area-badge.large-size::after {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0px;
    opacity: 0;
    content: '';
    animation: moveLeftToRight 500ms ease-out;
    animation-delay: 0s;
    animation-delay: 1s;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(128, 186, 232, 0) 100%, rgba(125, 185, 232, 0) 100%);
}

div.area-badge.locked {
    cursor: default;
    filter: grayscale(0.95);
    font-size: var(--font-size-s);
    opacity: .1;
    color: var(--groninger-paard);
    background: none;
    box-shadow: var(--default-box-shadow);
}

div.progress-bar {
    background: var(--light-grey);
    border-radius: var(--default-radius);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    margin-top: var(--spacing-m);
    margin-bottom: var(--spacing-m);

}

div.progress-bar .count-bar {
    border-radius: var(--default-radius);
    padding-top: var(--spacing-m);
    padding-bottom: var(--spacing-m);
    color: var(--bakstain-light);
    background: var(--kiem);
    opacity: 0;
    animation: loadingCountBar 500ms ease-in-out;
    animation-delay: 500ms;
    animation-fill-mode: forwards;
}

div.progress-bar .non-count-bar {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: var(--spacing-m);
    color: var(--groninger-paard);
}

@keyframes loadingCountBar {
    0% {
        opacity: 0;
        transform: translateX(-100%);
        color: var(--kiem);
    }

    50% {
        color: var(--kiem);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
        color: var(--bakstain-light);
    }
}

.area-detail-modal .area-wrap {
    height: 200px;
    position: relative;
    padding-top: var(--spacing-xl);
}

.area-detail-modal .area-wrap #map {
    position: absolute;
    opacity: .8;
    height: calc(230px + var(--spacing-m));
    top: 0px;
    width: 100%;
    border-radius: var(--default-radius);
    margin-bottom: var(--spacing-xl);
    box-shadow: var(--floating-shadow);
}

.year-detail-modal,
.soort-detail-modal,
.area-detail-modal,
.points-detail-modal,
.found-an-object-modal {
    text-align: center;
    padding-top: calc(var(--spacing-xl) + var(--spacing-xl) + var(--spacing-xl));
}

.found-an-object-modal ul.found-container {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

ul.found-container li.found {
    position: relative;
    opacity: 0;
    border-radius: var(--default-radius);
    margin-bottom: var(--spacing-xl);
}

ul.found-container li.found.horizontal {
    display: grid;
    grid-template-columns: 1fr 1fr;
    vertical-align: middle;
    align-items: center;
}

ul.found-container li.found:nth-child(1) {
    animation: var(--animate-fade-in);
    animation-delay: 500ms;
    animation-fill-mode: forwards;

}

ul.found-container li.found:nth-child(2) {
    animation: var(--animate-fade-in);
    animation-delay: 700ms;
    animation-fill-mode: forwards;
}

ul.found-container li.found:nth-child(3) {
    animation: var(--animate-fade-in);
    animation-delay: 1000ms;
    animation-fill-mode: forwards;
}

ul.found-container li.found:nth-child(4) {
    animation: var(--animate-fade-in);
    animation-delay: 1300ms;
    animation-fill-mode: forwards;
}

ul.found-container li.found:nth-child(5) {
    animation: var(--animate-fade-in);
    animation-delay: 1600ms;
    animation-fill-mode: forwards;
}

ul.found-container .found .info {
    font-weight: 600;
    position: relative;
    text-align: center;
    display: block;
    margin-bottom: var(--spacing-xl);
}

ul.found-container .found.horizontal .info {
    margin-bottom: 0px;
}

ul.found-container .found .year-badge,
ul.found-container .found .area-badge,
ul.found-container .found .soort-badge,
ul.found-container .found .points-badge {
    margin: 0px auto;
    pointer-events: none;
    position: relative;
    display: block;
}

@keyframes turnLikeACoin {
    0% {
        transform: rotateY(0deg);
        color: #8f7200;
    }

    50% {
        transform: rotateY(90deg);
        color: transparent;
    }

    100% {
        transform: rotateY(0deg);
        color: #8f7200;
    }
}

.profile-modal .button-wrap button {
    color: var(--white);
    display: block;
    margin-top: var(--spacing-xl);
}

@keyframes scaleIntoView {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes slideIntoView {
    0% {
        transform: scale(0);
        opacity: 0;
        bottom: -100%;
    }

    100% {
        transform: scale(1);
        opacity: 1;
        bottom: 0%;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes modalPopUp {
    0% {
        bottom: -100%;
    }

    100% {
        bottom: 0%;
    }
}

@keyframes bottomSheetPopUp {
    0% {
        transform: translateY(100%);
    }

    50% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes flashyReveal {
    0% {
        opacity: 0;
        transform: scale(.8);
    }

    75% {
        opacity: 1;
        transform: scale(1.1);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes moveLeftToRight {
    0% {
        transform: translateX(-100%);
        opacity: 1;
    }

    100% {
        transform: translateX(100%);
        opacity: 1;
    }
}

@keyframes heightJump {
    0% {
        height: 80px;
    }

    90% {
        height: 80px;
    }

    95% {
        height: 85px;
    }

    98% {
        height: 78px;
    }

    100% {
        height: 80px;
    }
}